@media screen and (max-width: 768px) {
  .header {
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .header__search {
    width: 100%;
  }

  .header__search__input {
    padding: 10px;
    height: 40px;
  }

  .header__search__submit-btn {
    height: 40px;
    width: 40px;
  }

  .header__links {
    display: none;
  }
}
