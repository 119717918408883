.home {
  min-height: 100dvh;
  display: grid;
  /* flex-direction: column; */
}

.home__welcome-section {
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: auto;
  /* margin-top: 20px; */
  width: calc(100% - 20px);
  align-items: center;
  
}

.home__welcome-section__title {
  font-weight: 100;
  font-size: 1.3em;
}

.home__welcome-section__slogan {
  font-size: 0.9em;
  animation: opc infinite alternate;
}

.home__welcome-section__slogan span {
  color: var(--accent-2);
  font-weight: 800;
}

.home__welcome-section__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
}

.home__welcome-section__list__item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.home__welcome-section__list__item svg {
  color: var(--accent-2);
}

.home__welcome-section__list__item span {
  font-size: 0.8em;
  color: var(--accent);
}

.home__welcome-section__links {
  display: flex;
  gap: 15px;
}

.home__welcome-section__links__button {
  padding: 15px;
  border: none;
  cursor: pointer;
  /* width: 150px; */
  display: flex;
  align-items: center;
  font-size: 0.8em;
  justify-content: center;
  border-radius: 1em;
  gap: 10px;
  text-decoration: none;
}

.home__welcome-section__links__button svg{
  color: var(--accent);
  font-size: 1.5em;
}

.home__sort {
  color: #fff;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-items: center;
  gap: 5px;
  padding: 10px;
  max-width: 600px;
  width: 100%;
  justify-self: center;
  margin-block-start: 20px;
}

.home__sort .--selected {
  color: var(--accent);
}

.home__sort label {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;
  align-items: center;
}

.home__sort label:not(.--selected) {
  opacity: 0.3;
}

.home__sort label.--selected {
  color: var(--accent);
}

.home__sort input {
  display: none;
}

.home__cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 40px;
  justify-items: center;
  padding: 30px;
  flex-grow: 1;
}

.in-load {
  display: none !important;
}

.home__sort__text {
  font-size: 0.8em;
}

.home__cards__empty-query {
  min-height: 50vh;
  display: flex;
  align-items: center;
  color: #747474;
  font-style: italic;
  font-size: 0.8em;
  justify-content: center;
}

.home__pages {
  padding: 20px;
  display: flex;
  /* gap: 5px; */
  align-items: center;
  justify-content: center;
}

.home__pages form {
  display: flex;
}

.home__pages input {
  border: none;
  outline: none;
  height: 30px;
  padding: 5px;
  font-size: 1em;
  text-align: center;
  width: 80px;
  border-radius: 0;
}

.home__pages div {
  height: 30px;
  width: 1px;
  background-color: #747474;
}

.home__pages span {
  border: none;
  outline: none;
  height: 30px;
  padding: 5px;
  /* background-color: #f8f8f8; */
  font-size: 1em;
  text-align: center;
  width: 80px;
  border-radius: 0;
  color: var(--accent);
}

.home__pages__page-btn-arrow {
  border: none;
  background-color: var(--accent);
  height: 30px;
  font-size: 1.2em;
  cursor: pointer;
  width: 30px;
  padding-top: 3px;
  color: #fff;
}

.home__pages__page-btn-arrow:first-child {
  border-radius: 0.25em 0 0 0.25em;
}

.home__pages__page-btn-arrow:last-child {
  border-radius: 0 0.25em 0.25em 0;
}

.bg-dark .home__pages__page-btn-arrow {
  color: #222;
}

.home__pages__page-btn-arrow:disabled {
  opacity: 0;
}
