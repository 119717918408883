.banner {
  width: 100dvw;
  /* aspect-ratio: 3 / 1; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* color: #fff; */
  gap: 20px;
  max-width: 600px;
  position: relative;
}

.banner__cover {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1.8 / 1;
  height: 100%;
  object-position: 0% 60%;
}

.banner__text {
  position: absolute;
  font-size: 100%;
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 5px;
  /* color: var(--accent); */
}

/* .bg-light .banner__text {
  background-color: #ffffff2a;
}

.bg-dark .banner__text {
  background-color: #00000027;
} */

.banner__text__name {
  font-size: 12px;
  font-weight: 100;
  padding: 5px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.banner__text__name__icon {
  color: var(--accent);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner__text__name, .banner__text__nick {
  padding: 5px 10px;
  border-radius: 25px;
}

.banner__text__nick {
  font-size: 8px;
  color: #818080;
  /* opacity: 0.5; */
}

/* .banner div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
} */

.banner h1 {
  font-size: 3em;
}

.banner ul {
  /* margin-inline-start: 10px; */
  list-style: none; /* Remove os marcadores padrão */
  padding: 0;
  gap: 5px;
  display: flex;
  flex-direction: column;
}

.banner ul li {
  font-size: 0.8em;
}

.banner ul li::before {
  content: "✦"; /* Insere um marcador personalizado */
  color: var(--accent);
  font-size: 0.7rem;
  margin-right: 10px;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  font-weight: 100;
  /* margin-block-start: 20px; */
  background-color: var(--accent);
  /* color: #fff; */
  font-size: 12px;
  padding: 5px;
}