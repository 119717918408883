.footer {
  margin-top: 50px;
  background-color: var(--accent);
  /* min-height: 200px; */
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-size: 0.8em;
  justify-self: flex-end;
  color: #fff;
  align-items: flex-start;
}

.bg-dark .footer {
  color: #222;
}

.footer__theme__buttons {
  display: flex;
  border-radius: 25px;
  overflow: hidden;
  gap: 10px;
  padding: 2px;
}

.footer__theme__buttons__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  font-size: 1.3em;
  border: none;
  cursor: pointer;
  background: none;
  color: var(--accent);
  border-radius: 50%;
}

.footer__theme__buttons__button.--selected {
  background-color: var(--accent);
  color: #fff;
}

.bg-dark .footer__theme__buttons__button.--selected {
  color: #222;
}

.footer p {
  text-align: justify;
}

.footer p span {
  text-decoration: underline;
}

.footer h3 {
  text-align: center;
  font-weight: 100;
  font-size: 0.8em;
}

.footer__premium {
  text-decoration: underline;
  cursor: pointer;
}

.footer__form {
  display: flex;
  gap: 10px;
  align-items: center;
}

.footer__input__code {
  padding: 0 10px;
  outline: none;
  border: none;
  border-radius: 0.5em;
  height: 36px;
}

.footer__input__submit {
  height: 36px;
  aspect-ratio: 1 / 1;
  border: none;
  border-radius: 0.5em;
  font-size: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent);
  cursor: pointer;
}