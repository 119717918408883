@keyframes toLeft {
  0% {
    opacity: 0%;
    transform: translateX(100px);
  }
  100% {
    opacity: 100%;
    transform: translateX(0);
  }
}

.db-click-notify {
  position: fixed;
  bottom: 0;
  /* width: calc(100vh - 10px); */
  max-width: 340px;
  padding: 20px;
  margin: auto;
  right: 10px;
  border: 1px solid var(--accent);
  bottom: 5px;
  display: flex;
  gap: 20px;
  align-items: center;
  animation: toLeft 0.5s;
  border-radius: 1.3em;
}

.db-click-notify__close-btn {
  color: var(--accent);
  width: 50px;
  height: 50px;
  font-size: 1.3em;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.db-click-notify__text svg {
  /* vertical-align: baseline; */
  /* padding-top: 3px; */
  margin-inline-end: 3px;
  margin-block-end: 4px;
  vertical-align: middle;
  color: var(--accent);
}