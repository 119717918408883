* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: product sans;
}

*::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: product sans;
  src: url("./fonts/Product\ Sans\ Regular.ttf");
}

@font-face {
  font-family: gardenia;
  src: url("./fonts/gardenia-extrabold.otf");
}

@font-face {
  font-family: luxury;
  src: url("./fonts/glastone_2/Glastone-Regular.otf");
}
