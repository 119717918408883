.lazy-image-wrapper.loading {
  /* background-color: red; */
  position: relative;
  overflow: hidden;
}

.bg-dark .lazy-image-wrapper.loading {
  animation: loadingDark 1s infinite alternate;
}

.bg-light .lazy-image-wrapper.loading {
  animation: loadingLight 1s infinite alternate;
}

.lazy-image-wrapper {
  display: flex;
}

@keyframes loadingDark {
  0% {
    background-color: var(--dark);
  }
  100% {
    background-color: #444;
  }
}

@keyframes loadingLark {
  0% {
    background-color: var(--light);
  }
  100% {
    background-color: #fafafa;
  }
}
