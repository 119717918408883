@media screen and (max-width: 600px) {
  .pack-content {
    padding: 0 20px;
    display: flex;
    gap: 10px;
  }

  .pack__pack-content__right-content__title {
    letter-spacing: -0.5px;
    /* border-left: 3px solid #6666663b;
    padding-left: 10px; */
    /* text-align: start; */
  }

  .pack__pack-content__right-content {
    /* align-items: flex-start; */
    justify-content: space-evenly;
    padding: 0;
    /* text-align: start; */
  }

  .pack__pack-content__right-content a {
    /* flex-grow: 1; */
    width: 100%;
  }
}
