@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  80% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes moveBackground {
  0% {
    background-position: top;
  }
  100% {
    background-position: bottom;
  }
}


.card {
  width: 100%;
  /* box-shadow: 0 0 5px #79797962; */
  display: flex;
  flex-direction: column;
  /* transition: 0.5s; */
  position: relative;
  overflow: hidden;
  /* border: 1px solid transparent; */
  border-radius: 1.5em;
  /* padding: 10px; */
  background-color: #79797913 !important;
}

.card__cover__unavailable {
  filter: grayscale();
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card__cover__unavailable__alert {
  
  /* background-color: #616161; */
  width: 150%;
  display: flex;
  justify-content: center;
}

.card-container {
  width: 100%;
  padding: 3px;
  border-radius: 0.5em;
  border-radius: 1.6em;
}

.card-container.--popular {
  background-image: var(--popular);
  /* background-size: cover; */
  background-repeat: repeat;
  background-size: auto 200%;
  animation: moveBackground 1s linear infinite;
}

.card__icons {
  position: absolute;
  display: flex;
  right: 0;
  top: 0;
  flex-direction: column;
  gap: 5px;
  border-radius: 0 0 0 50%;
}

.card__icons__icon {
  display: flex;
  color: var(--accent);
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 1.1em;
}

.--popular .card__icons__icon {
  color: #ff7504;
}

.card__thumbnail {
  width: 100%;
  aspect-ratio: 1 / 1;
  /* filter: brightness(0); */
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
}

.card__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px 0 0;
}

.card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card__info__name {
  font-weight: 100;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.card__info__name svg {
  color: var(--accent);
}

.card__info__name__verified {
  color: var(--accent);
  /* color: #10db65; */
  font-size: 0.8em;
  margin-block-start: 1px;
  font-size: 1em;
}

.card.--popular .card__info__name__verified {
  color: #ff7504;
}

.card__tags {
  display: flex;
  gap: 5px;
}

.card__tags .explicit {
  color: #a7a7a7;
}

.card__tags .video {
  color: #11c9f7;
}

.card__tags .gb {
  color: #0ab918;
}

.card__info__date {
  display: flex;
  gap: 5px;
}

.card__info__date span {
  font-size: 0.8em;
  color: #969696;
}

.card__info__site {
  font-size: 0.8em;
  text-transform: capitalize;
  color: var(--accent-2);
  /* opacity: 0.6; */
}

.card__info__buttons {
  display: flex;
}

.card__info__link {
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  /* background-color: var(--accent); */
  text-decoration: none;
  /* border-radius: 0.5em; */
  font-size: 0.7em;
  display: flex;
  gap: 5px;
  flex-grow: 1;
  /* color: var(--light-2); */
  /* border-radius: 0 0 1em 1em; */
  width: 100%;
  background-color: #7979791c;
}

.card__info__link__price {
  margin-inline-start: 5px;
  background-color: #35c984;
  padding: 3px 8px;
  font-size: 8px;
  border-radius: 0.5em;
  /* color: #fff; */
}

.card__info__link__price.--premium {
  background-color: #ffb004;
}

/* .--popular .card__info__link {
  background-image: var(--popular);
  background-repeat: repeat;
  background-size: cover;
  background-position: 100% 100%;
  animation: moveBackground 1.5s linear infinite;
  filter: hue(200);
} */

/* .--popular .card__info__link {
  color: #fff;
} */

/* .bg-dark .card__info__link {
  color: #222;
} */

.card__info__link svg {
  font-size: 1.5em;
}

.temp-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: #ffffff7c; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent);
  animation: fade 1s;
  z-index: 2;
}

.--popular .temp-element {
  color: #ff7504;
}

.bg-dark .temp-element {
  background-color: var(--dark);
}

.bg-light .temp-element {
  background-color: var(--light);
}

.temp-element svg {
  font-size: 3em;
  animation: pulse 0.5s;
}

