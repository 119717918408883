.header {
  display: flex;
  /* grid-template-columns: repeat(3, 1fr); */
  position: sticky;
  top: 0;
  left: 0;
  padding: 20px;
  height: 90px;
  z-index: 3;
  align-content: center;
  /* box-shadow: 0 0 3px #79797938; */
  justify-content: space-between;
}

.header__logo {
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
}

.header__logo__img {
  margin-block-end: 5px;
  width: 40px;
  pointer-events: none;
  padding: 5px;
}

.header__logo__text {
  font-size: 1.2em;
  display: inline-block;
  font-weight: 100;
}

.header__logo__text__only,
.header__logo__text__free {
  font-family: gardenia;
  font-weight: 100;
  color: var(--accent-2);
}

.header__logo__text__free {
  color: var(--accent);
}

.header__search {
  justify-self: center;
  /* width: 100%; */
  display: flex;
  /* border-bottom: 1px solid var(--accent); */
  border-radius: 1em;
  overflow: hidden;
}

.header__search__input {
  padding: 10px;
  flex-grow: 1;
  border: none;
  outline: none;
  border-radius: 0;
  width: 100%;
}

.header__search__search-icon {
  padding: 5px 10px;
  /* background-color: var(--accent); */
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--accent);
  /* color: #fff; */
  cursor: pointer;
}

.bg-dark .header__search__search-icon {
  /* color: #222; */
}

.header__search__search-icon__icon {
  font-size: 1.2em;
}

.header__links {
  justify-self: flex-end;
  display: flex;
  /* background-color: red; */
  align-items: center;
  font-size: 1.6em;
  gap: 20px;
}

.header__links a {
  color: var(--accent);
}