.pack-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 40px;

  /* grid-template-columns: 40vw 1fr; */
}

.pack-images {
  max-width: 80vh;
  width: 100%;
}

.pack__pack-content__right-content {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  margin: 10px 0;
  padding: 40px 0;
  text-align: center;
  width: 100%;
  /* min-height: calc(100vh - 250px); */
  transition: 0.5s;
}

.pack__pack-content__right-content .slick-list {
  margin: 0 -7px;
  & .slick-slide > div {
    padding: 0 5px;
  }
}

.pack__pack-content__right-content__name {
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* gap: 10px; */
  width: 100%;
  padding: 20px 0;
  /* aspect-ratio: 2 / 1; */
  max-height: 40vh;
  border-radius: 1em;
  /* background-image: url("../../../assets/logo.png");
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: 140% 10%;
  background-blend-mode: difference; */
}

.pack__pack-content__right-content__name h1 {
  display: flex;
  align-items: center;
  gap: 5px;
  text-align: start;
  /* color: var(--accent-2); */
}

.pack__pack-content__right-content__name div {
  display: flex;
  align-items: center;
  gap: 5px;
  font-weight: 100;
}

.pack__pack-content__right-content__name span {
  align-items: flex-end;
  border-radius: 0.5em;
  display: flex;
  font-weight: 100;
  padding: 2px;
  opacity: 0.5;
}

.pack__pack-content__right-content__name svg {
  /* color: var(--accent); */
  /* font-size: 2em; */
  height: 100;
  /* background-color: #ff7504; */
}

.pack__pack-content__right-content__title {
  font-size: 1em;
  font-weight: 100;
  text-align: center;
  opacity: 0.8;
  width: 100%;
}

.pack__pack-content__right-content__title__site {
  color: var(--accent);
}

.--popular .pack__pack-content__right-content__title__site {
  color: #ff7504;
}

.pack__pack-content__right-content a {
  flex-grow: 0;
  width: 200px;
}

.pack__pack-content__right-content__buttons__like {
  align-items: center;
  background: none;
  border: none;
  color: var(--accent);
  cursor: pointer;
  display: flex;
  display: inline;
  font-size: 1.2em;
  justify-content: center;
  margin-inline-end: 10px;
  vertical-align: middle;
}

.pack__pack-content__right-content__nick {
  font-size: 1em;
  font-style: italic;
  font-weight: 100;
  opacity: 0.5;
}

.pack__pack-content__right-content__buttons {
  display: grid;
  grid-template-columns: 1fr 100px;
  max-width: 500px;
  width: 100%;
}

.pack__pack-content__right-content__buttons__link {
  border-radius: 1em 0 0 1em;
  width: 100% !important;
}

.pack__pack-content__right-content__options {
  align-items: center;
  border-radius: 1em;
  display: flex;
  font-size: 0.8em;
  /* gap: 10px; */
  justify-content: space-evenly;
  padding: 10px;
  position: relative;
}

.link {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  width: 100%;
}

.link.--premium {
  display: flex;
}

.link select {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.link__type {
  width: 100%;
  /* background-color: #ff7504; */
  padding: 15px;
  border-radius: 1em;
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: var(--accent);
  color: #fff;
  text-decoration: none;
}

.bg-dark .link__type {
  color: var(--dark);
}

/* .pack-content.--popular .link__type {
  background-image: var(--popular);
  background-repeat: repeat;
  background-size: 200% auto;
  animation: moveBackground 1.5s linear infinite;
} */

.link__type > svg {
  /* font-size: 2em; */
  height: 25px;
  width: 25px;
  border-radius: 1em;
}

.link__type_btn {
  flex-grow: 1;
  height: 100%;
}

.link__type__btn {
  color: #fff;
  width: 100%;
  height: 100%;
  /* border: none; */
  /* text-align: start; */
  font-size: 1em;
  display: flex;
  gap: 5px;
  align-items: center;
  /* background-color: var(--accent); */
  justify-content: space-between;
  font-size: 0.8em;
}


.bg-dark .link__type__btn {
  color: var(--dark);
}

.link__type__btn svg {
  font-size: 1.5em;
}
