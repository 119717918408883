@keyframes rotate {
  0% {
    transform: rotate(0deg);
    font-size: 0.1em;
  }
  100% {
    transform: rotate(360deg);
    font-size: 1em;
  }
}

@keyframes rotateInfinite {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes entry {
  0%, 60% {
    width: 0;
    margin-inline-start: 0;
  }
  100% {
    width: 75px;
    margin-inline-start: 5px;
  }
}

.gallery {
  display: grid;
  gap: 2px;
  overflow-y: auto;
}

.gallery-view {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000cb;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gallery-view__close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  border: none;
  color: var(--accent);
  font-size: 1.8em;
  cursor: pointer;
}

.gallery-view__slider {
  padding: 10px 30px;
  max-width: 80vh;
  width: 100%;
}

.gallery__title  {
  display: flex;
  align-items: center;
  padding: 20px 40px;
  justify-content: center;
  width: 100%;
  aspect-ratio: 2/1;
  max-height: 300px;
  font-weight: 100;
  overflow: hidden;
}

.gallery__title svg {
  color: var(--accent);
  /* animation: rotate .5s, rotateInfinite 1s infinite; */
  z-index: 1;
}

.gallery__title span {
  animation: entry 1.2s;
  overflow: hidden;
  margin-inline-start: 5px;
}

.gallery__image {
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
}

.gallery__view__title {
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.gallery__view__title span {
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* background-color: rgba(54, 54, 54, 0.774); */
  /* padding: 15px 20px; */
  border-radius: 1em;
  color: #cecece;
}

.gallery__view__title svg {
  color: var(--accent);
}

.gallery-zoom {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
}

.gallery-zoom button {
  color: var(--accent);
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1 / 1;
  font-size: 1.2em;
  border: none;
  cursor: pointer;
}

.gallery-zoom button:first-child {
  border-radius: 50% 0 0 50%;
}

.gallery-zoom button:last-child {
  border-radius: 0 50% 50% 0;
}
