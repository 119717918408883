.card-image {
  /* display: grid; */
  grid-template-columns: 1fr 1fr;
}

.card-image__image {
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: cover;
  pointer-events: none;
  /* filter: blur(10px); */
  border-radius: 1em;
}

.image__container {
  aspect-ratio: 2 / 2;
  width: 100%;
  border-radius: 0.5em 0.5em 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.image__container__blur {
  filter: opacity(0.5) saturate(0);
  /* background-size: 100%; */
  background-size: cover;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-position: 50%;
}

.image__container img {
  aspect-ratio: 1 / 1;
  width: 50%;
  border-radius: 1.5em;
  position: absolute;
  object-fit: cover;
}
/* 
.image__container img:nth-child(2) {
  transform: rotate(10deg);
  z-index: 2;
}

.image__container img:nth-child(3) {
  transform: rotate(20deg);
  z-index: 1;
} */
