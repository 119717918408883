.search__title {
  aspect-ratio: 2 / 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8em;
  margin: auto;
  max-height: 40vh;
}

.search__title h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.search__title span {
  color: var(--accent-2);
  padding: 10px;
  border-radius: 1em;
  max-width: 80vw;
  overflow: hidden;
  text-overflow: ellipsis;
}