:root {
  --dark: #000000;
  --dark-2: #111;
  --light: #fff;
  --light-2: #f7f7f7;
  /* --accent: #008bce; */
  /* --accent: #fe271b; */
  --accent: #bd5b37;
  /* --accent: #eb5931; */
  /* --accent-op03: #bd5b376c;
  /* --accent-2: #00aef1; */
  /* --accent-2: #9a1f0f; */
  --accent-2: #faa962;
  /* --accent-2: #15a751; */
  /* --accent-2: #ffe283; */
  /* --accent: #02afef; */
  /* --accent-2: #018CCF; */
}

/* bg */

.bg-dark {
  background-color: var(--dark);
}

.bg-dark-2 {
  background-color: var(--dark-2);
}

.bg-light {
  background-color: #fff;
}

.bg-light-2 {
  background-color: var(--light-2);
}

/* c */

.c-dark {
  color: #cacaca;
}

.c-dark-2 {
  color: #000;
}

.c-light {
  color: #747474;
}

.c-light-2 {
  color: #fff;
}
